import { map } from 'rxjs';
import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { effect, OnDestroy, ViewChild, Component, ElementRef, OnInit } from '@angular/core';

import { CpfCnpjPipe } from '@lib-core/pipes';
import { AuthService } from '@lib-auth/auth';
import { AccountCustomersComponent } from '@lib-shared/modules/account';
import { CustomerModel, RepresentativeModel } from '@lib-core/models';
import { RouterService, AppLoaderService, CustomerService, GalleryService, ToastService } from '@lib-core/services';
import { getDataTableOptions, getDataTableOrderByIndex, getNotOrderableColumns } from '@lib-core/config';
import { CustomerStore, EbookImportStore, RepresentativeStore, SalesTeamStore } from '@lib-core/stores';

@Component({
	standalone: true,
	selector: 'shared-customer-select',
	imports: [CommonModule, CpfCnpjPipe, NgbModalModule, DataTablesModule, AccountCustomersComponent],
	templateUrl: './customer-select.component.html',
})
export class CustomerSelectComponent implements OnInit, OnDestroy {
	redirectUrl: string;
	customer: CustomerModel;
	customerList: CustomerModel[];
	representative: RepresentativeModel;

	dtOptions = {
		...getDataTableOptions(),
		...getNotOrderableColumns([3]),
		...getDataTableOrderByIndex(1),
		lengthChange: false,
	};

	@ViewChild('customerModal')
	private customerModal: ElementRef;

	@ViewChild('noSalesTeamModalError')
	private noSalesTeamModalError: ElementRef;

	constructor(
		private router: Router,
		private modalService: NgbModal,
		private authService: AuthService,
		private routerService: RouterService,
		private customerStore: CustomerStore,
		private appLoaderService: AppLoaderService,
		private activatedRoute: ActivatedRoute,
		private salesTeamStore: SalesTeamStore,
		private galleryService: GalleryService,
		private customerService: CustomerService,
		private ebookImportStore: EbookImportStore,
		private representativeStore: RepresentativeStore,
	) {
		effect(() => {
			this.representative = this.representativeStore.get();
			this.queryCustomers();
		});

		effect(() => {
			this.customer = this.customerStore.get();
		});
	}

	ngOnInit(): void {
		this.setRedirectUrl();
	}

	ngOnDestroy(): void {
		this.modalService.dismissAll();
	}

	setRedirectUrl(): void {
		this.activatedRoute.paramMap.pipe(map(() => window.history.state)).subscribe(data => {
			this.redirectUrl = data?.redirectUrl;
		});
	}

	queryCustomers(): void {
		this.appLoaderService.start();
		this.customerService.listCustomersByRepresentative(this.representative?.code).subscribe(response => {
			this.open();
			this.customerList = response.data;
			setTimeout(() => {
				this.appLoaderService.stop();
			}, 500);
		});
	}

	open(): void {
		this.modalService.open(this.customerModal, { size: 'xl', backdrop: 'static', keyboard: false}).result.then(
			() => {},
			() => {
				// TODO: verificar isso no b2b
				if (!this.customer?.code) {
					this.ebookImportStore.clear();
				}
			},
		);
	}

	cancel(): void {
		this.modalService.dismissAll();
		this.routerService.goBack();
	}

	select(customer: CustomerModel): void {
		this.galleryService.listSalesTeam(customer.code).subscribe({
			next: response => {
				if (!response.success || !response.data || response.data.length === 0) {
					this.showNoSalesTeamErrorModal();
					return;
				}
				this.salesTeamStore.setCustomerSalesTeamList(response.data);
				this.customerStore.set(customer);
				this.redirect();
			},
			error: () => {
				this.showNoSalesTeamErrorModal();
			},
		});
	}

	redirect(): void {
		this.appLoaderService.start();
		this.modalService.dismissAll();
		this.router.navigateByUrl(this.redirectUrl);
		setTimeout(() => {
			this.appLoaderService.stop();
		}, 200);
	}

	showNoSalesTeamErrorModal(): void {
		this.modalService.open(this.noSalesTeamModalError, { backdrop: 'static' }).result.then(
			() => {},
			() => {},
		);
	}

	logout(): void {
		this.authService.logout();
	}
}
