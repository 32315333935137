export const ENVIRONMENT = Object.freeze({
	production: false,
	apiSettings: {
		url: 'https://malwee-dev.geovendas.app/MalweeApiHomologacao',
	},
	geoSettings: {
		url: 'https://malwee-dev.geovendas.app/GEOvendas/rest',
		login: 'ibtech.loja',
		password: 'vkgN203@',
	},
	backOfficeSettings: {
		sigla: 'MLW',
		portalUrl: 'https://portal-qas.malwee.com.br',
		enableScrollToTopButton: true,
		accountSettings: {
			enableSalesReturn: true,
		},
		useMenuOnSidebar: false,
		customerLinkAddresses: {
			aboutUs: 'https://grupomalwee.com.br/o-grupo/',
			values: 'https://grupomalwee.com.br/o-grupo/#posicionamento',
			brands: {
				malwee: 'https://grupomalwee.com.br/malwee/',
				malweeKids: 'https://grupomalwee.com.br/malwee-kids-2/',
				basicamente: 'https://grupomalwee.com.br/basicamente/',
				enfim: 'https://grupomalwee.com.br/enfim-2/',
				carinhoso: 'https://grupomalwee.com.br/carinhoso-2/',
				basico: 'https://grupomalwee.com.br/basico-com/',
			},
			returns: 'https://b2b.malwee.com.br/institucional/devolucoes',
			callCenter: 'https://api.whatsapp.com/send?phone=554721077145&text=Ol%C3%A1%2C+temos+o+prazer+em+atend%C3%AA-lo.+Em+que+podemos+ajudar%3F',
			network: 'https://grupomalwee.com.br/negocio#rede-aqui-tem-malwee',
			kidsFranchise: 'https://grupomalwee.com.br/negocio#rede-aqui-tem-malwee',
			retailerServices: 'https://grupomalwee.com.br/negocio/#innovation',
			privacyPolicy: 'https://grupomalwee.com.br/politica-de-privacidade/',
			cookiesPolicy: 'https://grupomalwee.com.br/politica-de-cookies/',
			termsOfUse: 'https://grupomalwee.com.br/termos-de-uso/',
			privacyAndTermsOfUse: 'https://grupomalwee.com.br/politica-de-privacidade/',
			socialMedias: {
				facebook: 'https://www.facebook.com/grupomalwee',
				instagram: 'http://www.instagram.com/grupomalwee',
				youtube: 'https://www.youtube.com/channel/UCvd5IXTn2S4jv5hf5Q5_Tdg',
				linkedIn: 'https://www.linkedin.com/company/grupomalwee/',
			},
			streetAddress: 'Rua Bertha Weege, 200',
			zipCode: '89260-900',
			city: 'Jaraguá do Sul',
			state: 'SC',
			telephone: '(47) 2107-7200',
			telephoneWhatsapp: '(47) 2107-7145',
			email: 'sam@atendimento.malwee.com.br',
		},
		measureTableImageUrls: {
			WomanKids: 'https://geo-ebook.s3.amazonaws.com/dist/malwee/Temp/TabelaDeMedidas/FEMININO%20KIDS.png',
			ManKids: 'https://geo-ebook.s3.amazonaws.com/dist/malwee/Temp/TabelaDeMedidas/MASCULINO%20KIDS.png',
			WomanAdults: 'https://geo-ebook.s3.amazonaws.com/dist/malwee/Temp/TabelaDeMedidas/FEMININO%20ADULTO.png',
			ManAdults: 'https://geo-ebook.s3.amazonaws.com/dist/malwee/Temp/TabelaDeMedidas/MASCULINO%20ADULTO.png',
			WomanPlus: 'https://geo-ebook.s3.amazonaws.com/dist/malwee/Temp/TabelaDeMedidas/FEMININO%20PLUS.png',
			ManPlus: 'https://geo-ebook.s3.amazonaws.com/dist/malwee/Temp/TabelaDeMedidas/MASCULINO%20PLUS.png',
			WomanAdultJeans: 'https://geo-ebook.s3.amazonaws.com/dist/malwee/Temp/TabelaDeMedidas/JEANS%20FEMININO%20ADULTO.png',
			ManAdultJeans: 'https://geo-ebook.s3.amazonaws.com/dist/malwee/Temp/TabelaDeMedidas/JEANS%20MASCULINO%20ADULTO.png',
			WomanActiveEnfim: 'https://geo-ebook.s3.amazonaws.com/dist/malwee/Temp/TabelaDeMedidas/ENFIM%20FEMININO%20ACTIVE.png',
			ManActiveEnfim: 'https://geo-ebook.s3.amazonaws.com/dist/malwee/Temp/TabelaDeMedidas/ENFIM%20MASCULINO%20ACTIVE.png',
			WomanCasualEnfim: 'https://geo-ebook.s3.amazonaws.com/dist/malwee/Temp/TabelaDeMedidas/ENFIM%20FEMININO%20CASUAL.png',
			ManCasualEnfim: 'https://geo-ebook.s3.amazonaws.com/dist/malwee/Temp/TabelaDeMedidas/ENFIM%20MASCULINO%20CASUAL.png',
		},
	},
});
